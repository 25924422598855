<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >

          <!-- Basic Info tab -->
          <tab-content
            :before-change="validationBasicInfo"
            title="Basic Information"
          >
            <validation-observer
              ref="basicInfo"
              tag="form"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    :label-class="`h5`"
                    label="Select Organisation"
                  >
                    <v-select
                      id="selectStartup"
                      v-model="program.organisation_id"
                      :get-option-label="option => option.users_organizationtable.title"
                      :options="userOrgs"
                      :reduce="org => org.organization_id"
                      placeholder="Choose one from the list"
                    />
                    <hr>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-overlay
                :show="!program.organisation_id"
                rounded="sm"
              >
                <template #overlay>
                  <b-card
                    bg-variant="primary"
                    text-variant="light"
                  >
                    Please Select Organisation
                  </b-card>
                </template>
                <b-row>
                  <b-col
                    class="mb-2"
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Basic Information
                    </h5>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Program Type"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Program Type"
                        label-for="programType"
                      >
                        <v-select
                          id="programType"
                          v-model="program.type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="['Pre-incubation Program', 'Incubation Program', 'Accelerator Program']"
                          label="Program Type"
                          placeholder="Program Type"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Program Name"
                      label-for="programTitle"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Program Name"
                        rules="required"
                      >
                        <b-form-input
                          id="programTitle"
                          v-model="program.title"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Program Name"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Startup Capacity"
                      label-for="programCapacity"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Program Capacity"
                        rules="required"
                      >
                        <b-form-input
                          id="programCapacity"
                          v-model="program.capacity"

                          :state="errors.length > 0 ? false:null"
                          placeholder="Number of participants"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Program Description"
                      label-for="programDescription"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Program Description"
                        rules="required"
                      >
                        <b-form-input
                          id="programDescription"
                          v-model="program.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Program Description"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Ideal Startup Lifecycle Stage"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Ideal Startup Lifecycle Stage"
                        label-for="lifeCycleStage"
                      >
                        <v-select
                          id="lifeCycleStage"
                          v-model="program.lifeCycleStage"
                          :options="['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling']"
                          append-to-body
                          multiple
                          placeholder="Select one or multiple options"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>

                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Participant Industry/Sector"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                        label="Participant Industry/Sector"
                        label-for="industry"
                      >

                        <v-select
                          id="industry"
                          v-model="program.industry"
                          :options="['Technology', 'Agriculture']"
                          append-to-body
                          multiple
                          placeholder="Select one or multiple options"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <hr>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label="Fundraising Assistance"
                    >
                      <div class="d-flex">
                        <b-form-checkbox-group
                          v-model="program.fundraising"
                          :options="[{ text: 'Demo Day', value: 'demoDay', disabled: program.fundraisingDisable},
                                     { text: 'Personal Pitching', value: 'personalPitching', disabled: program.fundraisingDisable },
                                     { text: 'Liasoning', value: 'Liasoning', disabled: program.fundraisingDisable }
                          ]"
                          class="d-inline"
                          disabled-field="disabled"
                        />
                        <b-form-checkbox
                          v-model="program.fundraisingDisable"
                          class="custom-control-danger"
                          value="true"
                          @change="program.fundraisingDisable ? fundraising = [] : null "
                        >
                          None
                        </b-form-checkbox>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="!program.fundraisingDisable"
                    md="4"
                  >
                    <b-form-group
                      label="Ticket Size Lower limit"
                      label-for="fundRaisingSize_l"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Lower Limit"
                        rules="required"
                      >
                        <b-form-input
                          id="fundRaisingSize_l"
                          v-model="program.fundRaisingSize[0]"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Lower Limit"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="!program.fundraisingDisable"
                    md="4"
                  >
                    <b-form-group
                      label="Ticket Size Upper limit"
                      label-for="fundRaisingSize_u"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Upper Limit"
                        rules="required"
                      >
                        <b-form-input
                          id="fundRaisingSize_u"
                          v-model="program.fundRaisingSize[1]"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Upper Limit"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Inhouse Funds"
                      label-for="inhouseFunds"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Inhouse Funds"
                        rules="required"
                      >
                        <b-form-input
                          id="inhouseFunds"
                          v-model="program.inhouseFunds"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Total Inhouse Funds"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Criteria
                    </h5>
                  </b-col>
                  <b-col md="12">
                    <b-row
                      v-for="(item, i) in program.criteria"
                      :key="i"
                      class="mt-2"
                    >
                      <b-col>
                        <b-form-group
                          :label="`Criteria ${i + 1}`"
                          :label-for="`Criteria-${i + 1}`"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="`Criteria ${i + 1}`"
                          >
                            <b-form-input
                              :id="`Criteria-${i + 1}`"
                              v-model="item.criteria"
                              :state="errors.length > 0 ? false:null"

                              placeholder="Criteria Title"
                              type="text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="auto">
                        <b-button
                          v-if="program.criteria.length > 1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :class="{'mr-2': i === program.criteria.length-1}"
                          class="mt-2"
                          variant="outline-danger"
                          @click="program.criteria.splice(i-1, 1)"
                        >
                          <feather-icon
                            class="mr-25"
                            icon="XIcon"
                          />
                        </b-button>
                        <b-button
                          v-if="i === program.criteria.length-1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="mt-2"
                          variant="outline-primary"
                          @click="program.criteria.push({criteria: null, description: null})"
                        >
                          <feather-icon
                            class="mr-25"
                            icon="PlusIcon"
                          />
                          <span>Add Criteria</span>
                        </b-button>

                      </b-col>
                      <b-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          :name="`Criteria ${i + 1} Description`"
                        >
                          <b-form-input
                            :id="`description-${i + 1}`"
                            v-model="item.description"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Criteria Description"
                            type="text"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>

                    </b-row>
                  </b-col>
                  <b-col>
                    <hr>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <h5 class="mb-0">
                      Deliverables
                    </h5>
                  </b-col>
                  <b-col md="12">
                    <b-row
                      v-for="(item, i) in program.deliverables"
                      :key="i"
                      class="mt-2"
                    >
                      <b-col>
                        <b-form-group
                          :label="`Deliverable ${i + 1}`"
                          :label-for="`Deliverable-${i + 1}`"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="`Deliverable ${i + 1}`"
                          >
                            <b-form-input
                              :id="`Deliverable-${i + 1}`"
                              v-model="item.deliverable"
                              :state="errors.length > 0 ? false:null"

                              placeholder="Deliverable Title"
                              type="text"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="auto">
                        <b-button
                          v-if="program.deliverables.length > 1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :class="{'mr-2': i === program.deliverables.length-1}"
                          class="mt-2"
                          variant="outline-danger"
                          @click="program.deliverables.splice(i-1, 1)"
                        >
                          <feather-icon
                            class="mr-25"
                            icon="XIcon"
                          />
                        </b-button>
                        <b-button
                          v-if="i === program.deliverables.length-1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="mt-2"
                          variant="outline-primary"
                          @click="program.deliverables.push({deliverable: null, description: null})"
                        >
                          <feather-icon
                            class="mr-25"
                            icon="PlusIcon"
                          />
                          <span>Add Deliverable</span>
                        </b-button>

                      </b-col>
                      <b-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          :name="`Deliverable ${i + 1} Description`"
                        >
                          <b-form-input
                            :id="`description-${i + 1}`"
                            v-model="item.description"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Deliverable Description"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>

                </b-row>
              </b-overlay>
            </validation-observer>
          </tab-content>

        </form-wizard>

      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  // BCardText,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BRow,
  // BTab,
  // BTabs,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { getUserData } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    // AppTimeline,
    // AppTimelineItem,
    // BTabs,
    // BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    // BCardText,

    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      program: {
        organisation_id: null,
        programId: null,
        title: null,
        type: null,
        description: null,
        capacity: null,
        lifeCycleStage: [],
        industry: null,
        fundraisingDisable: false,
        fundraising: [],
        fundRaisingSize: ['', ''],
        inhouseFunds: null,
        // inhouseFundsSize: ['', ''],
        criteria: [
          {
            criteria: null,
            description: null,
          },
        ],
        deliverables: [
          {
            deliverable: null,
            description: null,
          },
        ],
        phases: [
          {
            title: null,
            description: null,
            objectives: [
              {
                title: null,
                description: null,
              },
            ],
            subphases: [{
              title: null,
              description: null,
              days: [
                {
                  activities: [
                    {
                      title: null,
                      operations_type: null,
                      duration: null,
                      description: null,
                      objectives: [
                        {
                          title: null,
                          description: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            }],
          },
        ],
      },
      phasesTab: null,
      required,
      email,
    }
  },
  computed: {
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      if (!arr.length) return []
      return arr
        .filter(obj => obj.role === 'superadmin')
        .filter(obj => obj) // Remove nulls
    },
  },
  methods: {
    /* eslint-disable no-param-reassign */
    formatPhases(phases, programId) {
      phases.forEach(phase => {
        phase.operations_type = 'Phase'
        phase.program_id = programId
        phase.programs_objectivestables = {
          data: phase.objectives,
        }
        delete phase.objectives
        phase.subphases.forEach(subphase => {
          subphase.operations_type = 'SubPhase'
          subphase.program_id = programId
          const activities = []
          subphase.days.forEach((day, dayIndex) => {
            day.activities.forEach(activity => {
              activity.day = dayIndex + 1
              activity.program_id = programId
              activity.programs_objectivestables = {
                data: activity.objectives,
              }
              delete activity.objectives
              activities.push(activity)
            })
            subphase.programs_operationstables = {
              data: activities,
            }
            delete subphase.days
          })
        })
        phase.programs_operationstables = {
          data: phase.subphases,
        }
        delete phase.subphases
      })
      return phases
    },
    formSubmitted() {
      if (!this.$route.params.id) {
        this.addProgram()
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Please Wait! Creating Program...',
          icon: 'LoaderIcon',
          variant: 'info',
        },
      })
    },
    addProgram() {
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation (
            $criteriaInput: [programs_shortlistingcriteriontable_insert_input!]!,
            $deliverableInput: [programs_deliverablelisttable_insert_input!]!
            ) {
            insert_programs_basicinfo_one(object: {
            status: "draft"
            organization_id_id: "${this.program.organisation_id}",
            title: "${this.program.title}",
            type: "${this.program.type}",
            description: "${this.program.description}",
            ideal_lifecycle_stage: "${this.program.lifeCycleStage.join(', ')}",
            capacity: "${this.program.capacity}",
            industry: "${this.program.industry}",
            fund_raising_assistance: "${this.program.fundraising.join(', ')}",
            inhouse_funds: "${this.program.inhouseFunds}",
            ticket_size_of_fund_raised: "${this.program.fundRaisingSize[1]}",
            inhouse_ticket_size: "${this.program.fundRaisingSize[0]}",
            programs_shortlistingcriteriontables: {data: $criteriaInput},
            programs_deliverablelisttables: {data: $deliverableInput}})
            {
                id
            }
        }`,
        variables: {
          criteriaInput: this.program.criteria.filter(criteria => criteria.criteria),
          deliverableInput: this.program.deliverables.filter(deliverable => deliverable.deliverable),
        },
        update: (store, { data: { insert_programs_basicinfo_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_basicinfo_one.id ? 'Program created.' : 'Failed to create program',
              icon: insert_programs_basicinfo_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_basicinfo_one.id ? 'info' : 'danger',
            },
          })
          if (insert_programs_basicinfo_one.id) {
            // Program Created, Create Phases
            // this.addPhases(insert_programs_basicinfo_one.id)
            window.location.href = '/programs'
          } else {
            // Error Occurred, stop loading
            this.mutationLoading = false
          }
        },
      })
      return this.error
    },
    addPhases(programId) {
      this.$apollo.mutate({
        mutation: gql` mutation($objects: [programs_operationstable_insert_input!]!) {
        insert_programs_operationstable(objects: $objects) {
          affected_rows
        }
      }`,
        variables: {
          objects: this.formatPhases(this.program.phases, programId),
        },
        update: () => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: insert_programs_operationstable.affected_rows ? 'Phases created successfully' : 'Failed to create phases',
          //     icon: insert_programs_operationstable.affected_rows ? 'CheckIcon' : 'XIcon',
          //     variant: insert_programs_operationstable.affected_rows ? 'success' : 'danger',
          //   },
          // })
          setTimeout(() => {
            this.mutationLoading = false
            window.location.href = '/programs'
          }, 2000)
        },
      })
    },
    validationBasicInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.basicInfo.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationPhaseStructure() {
      return new Promise((resolve, reject) => {
        this.$refs.phaseStructure.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationPhaseDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.phaseDetails.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              if (this.program.phases.length > 1) {
                this.$bvToast.toast('Please fill details for all phases!', {
                  title: 'Alert',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: true,
                  solid: true,
                })
              }
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
